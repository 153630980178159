<template>
  <a-modal
    ref="createModal"
    :title="readOnly ? '详情' : form.categoryId ? '类别编辑' : '新增操作'"
    :width="640"
    :visible="visible"
    @cancel="close"
    @ok="confirm"
    :confirmLoading="confirmLoading"
  >
    <a-spin :spinning="loading">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-row>
          <a-col :span="24" :pull="3">
            <a-form-model-item label="上级类别" prop="parentId">
              <a-input v-model="form.categoryName" style="display: none" />
              <a-tree-select
                v-model="form.parentId"
                :tree-data="categoryOptions"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                placeholder="Please select"
                tree-default-expand-all
                :replaceFields="treeReplaceFields"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="类别名称" prop="categoryName">
              <a-input v-model="form.categoryName" placeholder="请输入类别名称" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="状态" prop="status">
              <a-radio-group button-style="solid" v-model="form.status">
                <a-radio-button v-for="dict in statusOptions" :key="dict.dictValue" :value="dict.dictValue">
                  {{ dict.dictLabel }}
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="类别键值" prop="categoryKey">
              <a-input v-model="form.categoryKey" placeholder="请输入类别键值" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>

<script>
import { listCategory, addCategory, updateCategory } from '@/api/rongyi/resource/category'
// 表单字段
export default {
  data() {
    return {
      // 菜单列表
      menuOptions: [],
      // 部门列表
      categoryOptions: [],
      // 状态数据字典
      statusOptions: [],
      // 当前控件配置:
      confirmLoading: false,
      readOnly: false,
      visible: false,
      loading: false,
      // form: this.$form.createForm(this),
      // value: undefined,
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      // 表单属性:
      form: {},
      // 表单校验
      rules: {
        parentId: [{ required: true, message: '上级类别不能为空', trigger: 'blur' }],
        categoryName: [{ required: true, message: '部门名称不能为空', trigger: 'blur' }],
        orderNum: [{ required: true, message: '菜单顺序不能为空', trigger: 'blur' }],
        email: [
          {
            type: 'email',
            message: "'请输入正确的邮箱地址",
            trigger: ['blur', 'change'],
          },
        ],
        phone: [
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
      },
      treeReplaceFields: {
        children: 'children',
        title: 'categoryName',
        key: 'categoryId',
        value: 'categoryId',
      },
    }
  },
  created() {
    this.getDicts('sys_enable').then((response) => {
      this.statusOptions = response.data
    })

    listCategory().then((response) => {
      const root = response.rows.find((item) => {
        return item.parentId == '' || item.parentId == null || item.parentId == 0
      })
      const rootId = root ? root.categoryId : ''
      this.categoryOptions = this.handleTree(response.rows, 'categoryId', 'parentId', 'children', rootId, true)
      // this.categoryOptions = this.handleTree1(response.rows, 'categoryId','parentId','children','09fd9dde979c458eabc116299a4093cc')
      console.log('this.categoryOptions')
      console.log(this.categoryOptions)

      const category = { categoryId: 0, categoryName: '主类目', children: [] }
      this.categoryOptions.push(category)
    })
  },
  methods: {
    handleTree1(data, id, parentId, children, rootId) {
      id = id || 'id'
      parentId = parentId || 'parentId'
      children = children || 'children'
      rootId =
        rootId ||
        Math.min.apply(
          Math,
          data.map((item) => {
            return item[parentId]
          })
        ) ||
        0
      // 对源数据深度克隆
      const cloneData = JSON.parse(JSON.stringify(data))
      // 循环所有项
      const treeData = cloneData.filter((father) => {
        const branchArr = cloneData.filter((child) => {
          // 返回每一项的子级数组
          return father[id] === child[parentId]
        })
        father.children = branchArr.length > 0 ? branchArr : ''
        console.log('father')
        console.log(father)
        // 返回第一层
        return father[parentId] === rootId
      })
      return treeData !== '' ? treeData : data
    },
    // 由于要用传进来的值做判断,将显示和隐藏放在内部做处理
    show(data, readOnly) {
      if (data) {
        // 修改行为
        this.form = Object.assign({}, data) || {}
      } else {
        // 新增行为
        this.reset()
      }
      this.readOnly = typeof readOnly !== 'undefined' ? readOnly === true : false
      this.visible = true
    },
    // 关闭模态框
    close() {
      this.visible = false
      this.reset()
    },
    confirm() {
      let that = this
      this.confirmLoading = true
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let { hostId, userId } = that.$store.state.user
          hostId = typeof hostId == 'undefined' ? 0 : hostId
          console.log('hostId')
          console.log(hostId)
          that.form.universityId = hostId || that.rongyiConfig.rongyiUniversityId || '00000000000000000000000000000000'
          if (!this.isEmptyStr(this.form.categoryId)) {
            // 进行修改行为:
            // 暂时在本处处理若依最高级菜单下无子菜单情况下children是个字符串的bug
            // if (this.form.children === '') {
            //     this.form.children = []
            // }
            // this.filterStrArrary(this.form.children)
            // 刷新表格
            updateCategory(this.form).then((response) => {
              if (response.code === 200) {
                this.$message.success('修改成功')
                // 关闭本组件
                this.visible = false
                // 调用外部刷新列表方法
                this.$emit('handle-success')
                // 刷新表单
                this.reset()
                this.confirmLoading = false
              } else {
                this.$message.error(response.msg)
                this.confirmLoading = false
              }
            })
          } else {
            // 新增
            addCategory(this.form).then((response) => {
              if (response.code === 200) {
                this.$message.success('新增成功')
                // 关闭本组件
                this.visible = false
                // 调用外部刷新列表方法
                this.$emit('handle-success')
                // 刷新表单
                this.reset()
                this.confirmLoading = false
              } else {
                this.$message.error(response.msg)
                this.confirmLoading = false
              }
            })
          }
        } else {
          return (this.confirmLoading = false)
        }
      })
    },
    // 表单重置
    reset() {
      this.form = {}
    },
    filterStrArrary(list) {
      for (let index = 0; index < list.length; index++) {
        if (list[index].children === '') {
          list[index].children = []
        }
        if (list[index].children) {
          this.filterStrArrary(list[index].children)
        }
      }
    },
  },
}
</script>
